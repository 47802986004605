import lazy from '@whiz-cart/node-shared/lazy';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
import { serviceWorkerRegistration } from '../registerWorkers';

if (config?.firebase) {
    firebase.initializeApp(config.firebase);
}

const messaging = lazy<firebase.messaging.Messaging>(() => {
    const messaging = firebase.messaging();

    const init = lazy(async () => {
        const sw = await serviceWorkerRegistration;
        if (sw) {
            // Deprecated, but without deleteToken does not work. Bad Google 🙄
            messaging.useServiceWorker(sw);
        }
    });

    return {
        ...messaging,
        async getToken(...args) {
            await init();
            return messaging.getToken(...args);
        },
        async deleteToken() {
            await init();
            return messaging.deleteToken();
        },
    };
});

export default {
    ...firebase,
    messaging,
};

if (import.meta.hot) {
    import.meta.hot.dispose(() => {
        firebase.app().delete();
    });
    import.meta.hot.accept();
}
